<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>用户列表</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="toolsbar">
        <div style="float: left;margin-left: 20px;margin-top: 10px;">
          <a-button :loading="loading" style="margin-left: 0px" type="primary" @click="start">
            刷新
          </a-button>
        </div>
        <!--                <div class="serach_input">-->
        <!--                    <a-input-group compact>-->
        <!--                        <a-select defaultValue="用户名">-->
        <!--                            <a-select-option value="username">用户名</a-select-option>-->
        <!--                            &lt;!&ndash;                    <a-select-option value="username">用户名</a-select-option>&ndash;&gt;-->
        <!--                        </a-select>-->
        <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
        <!--                    </a-input-group>-->
        <!--                </div>-->
      </div>

      <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
        <!--                                                {{data}}-->

        <a-table
            :columns="userlist"
            :dataSource="data"
            :loading="loading"
            :pagination="pagination"
            bordered
            rowKey="id"
            @change="onChange"
        >
          <template slot="name" slot-scope="text, index" style="text-align: center">
            {{ text }}

            <a-tag v-if="index.vip_status === 1" :color="'red'">VIP</a-tag>
          </template>
          <template slot="login_name" slot-scope="text, index" style="text-align: center">
            {{ text }}
            <a-tag v-if="index.is_check_email === -1" :color="'orange'">邮箱待验证</a-tag>
          </template>
          <template slot="created_at" slot-scope="text, index" style="text-align: center">
            {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="updated_at" slot-scope="text, index" style="text-align: center">
            {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template slot="status" slot-scope="text, index" style="text-align: center">
            <a-tag v-if="index.status === 11" :color="'blue'"><span>未发</span></a-tag>
            <a-tag v-if="index.status === 10 && index.make_status === 1" :color="'blue'"><span>已发</span></a-tag>
            <a-tag v-if="index.status === 10 && index.make_status === -1" :color="'blue'"><span>队列中</span></a-tag>
          </template>
          <template slot="operation" slot-scope="text, index" style="text-align: center">
             <span style="margin-right: 5px">
                              <a @click="views(index)">查看</a>
                            </span>
            <span style="margin-right: 5px">
                              <a :href="server_url+'/'+index.pdf_src" target="_blank" download>下载</a>
                            </span>

          </template>
        </a-table>
        <a-modal
            title="证书预览"
            :visible="visible"
            :footer="null"
            width="50%"
            @cancel="onClose"
        >
<!--          {{Course}}-->
          <img :src="server_url+'/'+Course.img_src" style="width:100%" />
        </a-modal>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>


  </a-layout>

</template>

<script>
// import moment from "moment";
import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
import Config from "@/config";


const userlist = [
  {
    title: "id",
    dataIndex: "id",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "证书编号",
    dataIndex: "certificate_code",
    scopedSlots: {customRender: "certificate_code"}
  }, {
    title: "课程名称",
    dataIndex: "course_title",
    scopedSlots: {customRender: "course_title"}
  },
  {
    title: "学员",
    dataIndex: "customer_name",
    scopedSlots: {customRender: "customer_name"}
  },  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: {customRender: "status"}
  },
  {
    title: "结业时间",
    dataIndex: "created_at",
    scopedSlots: {customRender: "created_at"}
  }, {
    title: "证书生产时间",
    dataIndex: "updated_at",
    scopedSlots: {customRender: "updated_at"}
  },
  {
    title: '操作',
    width: "15%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

// import moment from "moment";
// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      userlist,
      data: [],

      pagination: {
        pageSize: 10,
      },
      current: "",
      Course: "",

      loading: false,
      visible: false,

      server_url: Config.data.server_url,

    };
  },
  created() {
    // 获取第一页内容
    this.doSearch(1, this.pagination.pageSize, "", "", "", "");

  },
  methods: {
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize, "", "", "");
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, text);
    },
    async onChange(pagination) {
      console.log(pagination.current)
      await this.doSearch(
          pagination.current,
          pagination.pageSize
      );
    },

    views(item) {
      console.log(item)
      this.visible=true;
      this.Course=item

    },
    download(item) {
      console.log(item)
    },
    onClose() {
      this.visible = false;
    },

    async popok() {
      if (this.edit.login_name == "") {
        Modal.warning({
          title: "请输入用户名"
        });
        this.popinfos = true;
        return
      }
      if (this.edit.password != this.edit.re_password) {
        Modal.warning({
          title: "两次密码不一致"
        });
        this.popinfos = true;
        return
      }
      let now = Date.now();
      let result = await this.$post("/api/admin/account/update", {
        uid: Storage.uid,
        token: Storage.token,
        edit_uid: this.edit.uid,
        save_value: {
          login_name: this.edit.login_name,
          password: this.edit.password,
          name: this.edit.name,
          sex: this.edit.sex,
          mobile: this.edit.mobile,
          t: now,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.doSearch(1, this.pagination.pageSize, "");
        Modal.info({
          title: "更新成功"
        });
      }
      this.popinfos = false;
    },

    async deleteuser(index) {
      // let now = Date.now();
      let result = await this.$post("/api/admin/customer/delete", {
        uid: Storage.uid,
        token: Storage.token,
        edit_uid: index.uid,
      });

      if (result.status === true) {
        this.data = []
        await this.doSearch(1, this.pagination.pageSize);
        Modal.info({
          title: "剔除成功"
        });
      }
    },

    async doSearch(page, limit, certificate_code, edit_uid, status, course_id) {
      this.loading = true;
      // let now = Date.now();
      console.log("uid", Storage.uid);
      console.log("token", Storage.token);
      let result = await this.$get("/api/admin/certificate/getListByCourse", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          limit: limit,
          page: page,
          certificate_code: certificate_code,
          edit_uid: edit_uid,
          status: status,
          course_id: course_id,

          // t: now,
        }
      });

      if (result.status === true) {

        // let data = [];
        if (result.data === "404 page not found") {
          Modal.error({
            title: "api服务器接口错误"
          });
          return
        }
        let infodatas = result.data;


        // for (let i in infodatas.list) {
        //   let info = infodatas.list[i];
        //
        //
        //   data[i] = {
        //     id: info.id,
        //     user_id: info.user_id,
        //     user_name: info.user_name,
        //     server_id: info.server_id,
        //     server_name: info.server_name,
        //     ban_flag: info.ban_flag,
        //     ban_start_time: moment(info.ban_start_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     ban_end_time: moment(info.ban_end_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     ban_msg: info.ban_msg,
        //     block_flag: info.block_flag,
        //     block_start_time: moment(info.block_start_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     block_end_time: moment(info.block_end_time * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //     block_reason: info.block_reason,
        //     ban_reason: info.ban_reason,
        //     block_msg: info.block_msg,
        //     // res: this.inforesdata,
        //     // start: info.start,
        //     // start: moment(info.start * 1000).format("YYYY/MM/DD hh:mm:ss"),
        //   };
        // }

        this.data = infodatas.list;
        let pagination = {...this.pagination};
        pagination.total = infodatas.total;
        this.pagination = pagination;

        this.loading = false;
      }

      this.loading = false;
    },


  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
</style>
